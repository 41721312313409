import { getDefaultOptions } from "./Options.js";
export const getRefs = options => {
  const _options = getDefaultOptions(options);
  const currentPath = _options.name !== undefined ? [..._options.basePath, _options.definitionPath, _options.name] : _options.basePath;
  return {
    ..._options,
    currentPath: currentPath,
    propertyPath: undefined,
    seen: new Map(Object.entries(_options.definitions).map(([name, def]) => [def._def, {
      def: def._def,
      path: [..._options.basePath, _options.definitionPath, name],
      // Resolution of references will be forced even though seen, so it's ok that the schema is undefined here for now.
      jsonSchema: undefined
    }]))
  };
};