import { BaseMessage, BaseMessageChunk, mergeContent, _mergeDicts, _mergeObj, _mergeStatus } from "./base.js";
/**
 * Represents a tool message in a conversation.
 */
export class ToolMessage extends BaseMessage {
  static lc_name() {
    return "ToolMessage";
  }
  get lc_aliases() {
    // exclude snake case conversion to pascal case
    return {
      tool_call_id: "tool_call_id"
    };
  }
  constructor(fields, tool_call_id, name) {
    if (typeof fields === "string") {
      // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-non-null-assertion
      fields = {
        content: fields,
        name,
        tool_call_id: tool_call_id
      };
    }
    super(fields);
    /**
     * Status of the tool invocation.
     * @version 0.2.19
     */
    Object.defineProperty(this, "status", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: void 0
    });
    Object.defineProperty(this, "tool_call_id", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: void 0
    });
    /**
     * Artifact of the Tool execution which is not meant to be sent to the model.
     *
     * Should only be specified if it is different from the message content, e.g. if only
     * a subset of the full tool output is being passed as message content but the full
     * output is needed in other parts of the code.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Object.defineProperty(this, "artifact", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: void 0
    });
    this.tool_call_id = fields.tool_call_id;
    this.artifact = fields.artifact;
    this.status = fields.status;
  }
  _getType() {
    return "tool";
  }
  static isInstance(message) {
    return message._getType() === "tool";
  }
  get _printableFields() {
    return {
      ...super._printableFields,
      tool_call_id: this.tool_call_id,
      artifact: this.artifact
    };
  }
}
/**
 * Represents a chunk of a tool message, which can be concatenated
 * with other tool message chunks.
 */
export class ToolMessageChunk extends BaseMessageChunk {
  constructor(fields) {
    super(fields);
    Object.defineProperty(this, "tool_call_id", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: void 0
    });
    /**
     * Status of the tool invocation.
     * @version 0.2.19
     */
    Object.defineProperty(this, "status", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: void 0
    });
    /**
     * Artifact of the Tool execution which is not meant to be sent to the model.
     *
     * Should only be specified if it is different from the message content, e.g. if only
     * a subset of the full tool output is being passed as message content but the full
     * output is needed in other parts of the code.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Object.defineProperty(this, "artifact", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: void 0
    });
    this.tool_call_id = fields.tool_call_id;
    this.artifact = fields.artifact;
    this.status = fields.status;
  }
  static lc_name() {
    return "ToolMessageChunk";
  }
  _getType() {
    return "tool";
  }
  concat(chunk) {
    return new ToolMessageChunk({
      content: mergeContent(this.content, chunk.content),
      additional_kwargs: _mergeDicts(this.additional_kwargs, chunk.additional_kwargs),
      response_metadata: _mergeDicts(this.response_metadata, chunk.response_metadata),
      artifact: _mergeObj(this.artifact, chunk.artifact),
      tool_call_id: this.tool_call_id,
      id: this.id ?? chunk.id,
      status: _mergeStatus(this.status, chunk.status)
    });
  }
  get _printableFields() {
    return {
      ...super._printableFields,
      tool_call_id: this.tool_call_id,
      artifact: this.artifact
    };
  }
}
export function defaultToolCallParser(
// eslint-disable-next-line @typescript-eslint/no-explicit-any
rawToolCalls) {
  const toolCalls = [];
  const invalidToolCalls = [];
  for (const toolCall of rawToolCalls) {
    if (!toolCall.function) {
      continue;
    } else {
      const functionName = toolCall.function.name;
      try {
        const functionArgs = JSON.parse(toolCall.function.arguments);
        const parsed = {
          name: functionName || "",
          args: functionArgs || {},
          id: toolCall.id
        };
        toolCalls.push(parsed);
      } catch (error) {
        invalidToolCalls.push({
          name: functionName,
          args: toolCall.function.arguments,
          id: toolCall.id,
          error: "Malformed args."
        });
      }
    }
  }
  return [toolCalls, invalidToolCalls];
}
export function isToolMessage(x) {
  return x._getType() === "tool";
}
export function isToolMessageChunk(x) {
  return x._getType() === "tool";
}